<template>
  <CyModal
    :header-title="$t('removeProjectHeadline')"
    :loading="loading"
    :action-btn-func="deleteProject"
    :cancel-btn-func="cancel"
    modal-type="delete">
    <p>{{ $t('removeProjectStatement') }} <b>{{ project.name }}</b>.</p>
    <p>{{ $t('removeProjectWarning') }}</p>
    <p>{{ $t('forms.areYouSure') }}</p>
  </CyModal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CyFormsProjectDelete',
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapActions('organization/project', [
      'DELETE_PROJECT',
    ]),
    async deleteProject () {
      this.loading = true
      const success = await this.DELETE_PROJECT(this.project)
      this.loading = false
      if (success) this.$emit('success')
    },
    cancel () {
      this.$emit('cancel')
    },
  },
  i18n: {
    messages: {
      en: {
        removeProjectHeadline: 'Delete a project',
        removeProjectStatement: 'You are going to delete the project',
        removeProjectWarning: 'Resources created via pipelines will not be deleted automatically. Please ensure you delete these yourself, as any remaining resources will add to your cloud provider costs.',
      },
      es: {
        removeProjectHeadline: 'Eliminar un proyecto',
        removeProjectStatement: 'Está a punto de eliminar el proyecto',
        removeProjectWarning: 'Los recursos creados a través de las pipelines no se eliminarán automáticamente. Asegúrese de eliminarlos usted mismo, ya que cualquier recurso restante aumentará los costos de su proveedor de cloud.',
      },
      fr: {
        removeProjectHeadline: 'Supprimer un projet',
        removeProjectStatement: 'Vous êtes sur le point de supprimer ce projet',
        removeProjectWarning: 'Les ressources créées via des pipelines ne seront pas supprimées automatiquement. Assurez-vous de les supprimer vous-même, car les ressources restantes augmenteront les coûts de votre fournisseur de cloud.',
      },
    },
  },
}
</script>
