<template>
  <div>
    <CyHeader
      v-if="project">
      <template #title>
        {{ project.name }}
      </template>
      <template #description>
        <div v-if="project.description">
          {{ project.description }}
        </div>
      </template>
      <template #actions>
        <div>
          <CyButton
            :icon="project.favorite ? 'star' : 'star_outline'"
            class="mr-2"
            theme="primary"
            variant="secondary"
            :loading="saving"
            @click.prevent.stop="toggleFavorite()">
            {{ $t('favorite') }}
          </CyButton>
          <CyMenu
            v-if="!_.$isEmpty($static.menuItems)"
            class="cy-project-card-header__menu"
            offset-y
            :items="$static.menuItems">
            <template #activator="{ on, attrs }">
              <CyButton
                icon="more_horiz"
                theme="primary"
                variant="secondary"
                v-bind="attrs"
                v-on="on">
                {{ $t('more') }}
              </CyButton>
            </template>
          </CyMenu>
        </div>
      </template>
      <template #metadata>
        <CyHeaderMetadata
          v-if="project.owner"
          :label="displayName(project.owner)">
          <CyAvatar
            xs
            :item="project.owner"/>
        </CyHeaderMetadata>
        <v-menu
          v-if="stackCanonical"
          open-on-hover
          open-delay="200"
          close-delay="200"
          offset-y>
          <template #activator="{ on }">
            <CyHeaderMetadata
              icon="fa-cubes"
              :label="stackCanonical"
              :to="{ name: 'stack', params: { stackRef } }"
              v-on="on"/>
          </template>
          <CyWizardServiceCard
            class="border-none"
            :max-width="440"
            data-cy="stack-card-summary"
            :service="project.service_catalog"/>
        </v-menu>
        <CyHeaderMetadata
          v-if="project.created_at"
          :label="getLabel('created')"/>
        <CyHeaderMetadata
          v-if="project.updated_at"
          :label="getLabel('updated')"/>
      </template>
    </CyHeader>

    <CyFormsProjectDelete
      v-if="modals.delete"
      :project="project"
      @cancel="closeModals"
      @success="() => $router.push({ name: 'projects' })"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CyFormsProjectDelete from '@/components/forms/project-delete.vue'
import CyHeaderMetadata from '@/components/header/metadata.vue'
import CyHeader from '@/components/header.vue'
import CyWizardServiceCard from '@/components/wizard/service-card.vue'
import { displayName } from '@/utils/helpers'

export default {
  name: 'CyHeaderVariantProject',
  components: {
    CyHeader,
    CyHeaderMetadata,
    CyFormsProjectDelete,
    CyWizardServiceCard,
  },
  data: () => ({
    saving: false,
    modals: {
      delete: false,
    },
  }),
  computed: {
    ...mapGetters('organization/project', [
      'project',
      'stackCanonical',
      'stackRef',
    ]),
    $static () {
      const { project: { canonical }, modals } = this
      const { canDisplay } = this.$cycloid.permissions
      return {
        menuItems: [
          {
            icon: 'construction',
            label: this.$t('Settings'),
            action: () => {
              this.$router.push({
                name: 'projectConfiguration',
                params: { backRouteTo: this.$route.name },
              })
            },
            permissionKey: 'UpdateProject',
          },
          {
            icon: 'delete',
            label: `${this.$t('forms.btnDelete')} ${this.$t('project')}...`,
            action: () => { modals.delete = true },
            color: 'error',
            permissionKey: 'DeleteProject',
          },
        ].filter(({ permissionKey }) => !permissionKey || canDisplay(permissionKey, canonical)),
      }
    },
  },
  methods: {
    ...mapActions('organization/project', [
      'SET_FAVORITE_PROJECT',
      'UNSET_FAVORITE_PROJECT',
    ]),
    displayName,
    closeModals () {
      for (const key in this.modals) this.modals[key] = false
    },
    async toggleFavorite () {
      this.$toggle.saving(true)
      const action = !this.project.favorite ? this.SET_FAVORITE_PROJECT : this.UNSET_FAVORITE_PROJECT
      await action(this.project)
      this.$toggle.saving(false)
    },
    getLabel (type) {
      const { created_at, updated_at } = this.project
      const { format, $formatTimeAgo, $getTimestamp } = this.$date

      const labelTemplates = {
        created: this.$t('created', [format($getTimestamp(created_at), 'MMM d, yyyy')]),
        updated: this.$t('updated', [$formatTimeAgo(updated_at)]),
      }

      return labelTemplates[type] || ''
    },
  },
  i18n: {
    messages: {
      en: {
        created: 'Created on {0}',
        updated: 'Updated {0}',
        favorite: 'Favorite',
        more: 'More',
      },
      es: {
        created: 'Creado el {0}',
        updated: 'Actualizado {0}',
        favorite: 'Favorito',
        more: 'Más',
      },
      fr: {
        created: 'Créé le {0}',
        updated: 'Mis à jour {0}',
        favorite: 'Favori',
        more: 'Plus',
      },
    },
  },
}
</script>
